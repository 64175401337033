
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$qloc-primary: mat.define-palette(mat.$indigo-palette);
$qloc-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$qloc-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$qloc-theme: mat.define-light-theme((
  color: (
    primary: $qloc-primary,
    accent: $qloc-accent,
    warn: $qloc-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($qloc-theme);

/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import '/node_modules/bootstrap/scss/bootstrap.scss';
@import "/src/app/variables.scss";
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    overflow-x: clip;
    display: block;
    background-color: rgb(248, 250, 254);
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
    color: $color-qloc-text;
}

body {
    margin: 0;
    //font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
    word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 0.95;
}

p,
span {
    line-height: 1.2;
}

.text-right {
    text-align: right;
}

.color-qloc,
.color-qloc:hover {
    color: $color-qloc;
}

.no-link,
.no-link:hover {
    text-decoration: none;
}

.link-grey,
.link-grey:link,
.link-grey:visited {
    color: $color-qloc-grey3;
}

.link-grey:hover {
    color: $color-qloc-grey4;
}

.link-grey:active {
    color: $color-qloc-grey1;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

btn {
    align-items: center;
}

.btn-qloc,
.btn-qloc:hover,
.btn-qloc:active,
.btn-qloc:visited {
    background-color: $color-qloc !important;
    color: white;
}

.form-check-input:checked {
    background-color: $color-qloc;
    border-color: $color-qloc;
}

.ngx-slider-span.ngx-slider-pointer {
    background-color: $color-qloc !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
    background-color: $color-qloc-grey4 !important;
}


/*.wow {
    visibility: hidden;
}*/

.sophos_infinite #outline {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: 0;
    -webkit-animation: sophos_infinite_anim 2.6s linear infinite;
    animation: sophos_infinite_anim 2.6s linear infinite;
}

.sophos_infinite img {
    z-index: 2;
    position: relative;
}

@-webkit-keyframes sophos_infinite_anim {
    12.5% {
        stroke-dasharray: 33.98873px, 242.77666px;
        stroke-dashoffset: -26.70543px;
    }
    43.75% {
        stroke-dasharray: 84.97183px, 242.77666px;
        stroke-dashoffset: -84.97183px;
    }
    100% {
        stroke-dasharray: 2.42777px, 242.77666px;
        stroke-dashoffset: -240.34889px;
    }
}

@keyframes sophos_infinite_anim {
    12.5% {
        stroke-dasharray: 33.98873px, 242.77666px;
        stroke-dashoffset: -26.70543px;
    }
    43.75% {
        stroke-dasharray: 84.97183px, 242.77666px;
        stroke-dashoffset: -84.97183px;
    }
    100% {
        stroke-dasharray: 2.42777px, 242.77666px;
        stroke-dashoffset: -240.34889px;
    }
}