/* 
 *     Ubuntu
 */


/* ubuntu-300 - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: local('Ubuntu Light'), local('Ubuntu-Light'), url('ubuntu-v14-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-300italic - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 300;
    src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'), url('ubuntu-v14-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-regular - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url('ubuntu-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-italic - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src: local('Ubuntu Italic'), local('Ubuntu-Italic'), url('ubuntu-v14-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-500 - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url('ubuntu-v14-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-500italic - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 500;
    src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'), url('ubuntu-v14-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-700 - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url('ubuntu-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}


/* ubuntu-700italic - latin */

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 700;
    src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'), url('ubuntu-v14-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('ubuntu-v14-latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}