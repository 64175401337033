$color-qloc: rgb(17, 72, 132);
$color-qloc-primary1: rgb(18, 78, 139); //Gradient1
$color-qloc-primary2: rgb(9, 124, 194);
$color-qloc-primary3: rgb(2, 146, 234); //Gradient1
$color-qloc-primary4: rgb(66, 190, 250); //Gradient1
$color-qloc-grey1: rgb(51, 51, 51); // Text-Color
$color-qloc-grey2: rgb(102, 102, 102);
$color-qloc-grey3: rgb(153, 153, 153);
$color-qloc-grey4: rgb(204, 204, 204);
$color-qloc-grey5: rgb(232, 232, 232); //BG Download Card
$color-qloc-grey6: rgb(242, 247, 251); //BG Card Footer
$color-qloc-grey7: rgb(250, 251, 253); //BF Content
$color-qloc-success: rgb(66, 181, 63);
$color-qloc-warning: rgb(255, 174, 0);
$color-qloc-error: rgb(233, 30, 3);
$color-qloc-white: rgb(255, 255, 255);
$color-qloc-gradient1: linear-gradient(0deg, $color-qloc-primary1 1%, $color-qloc-primary3 64%, $color-qloc-primary4 97%); //Gradient Header
$color-qloc-gradient2: linear-gradient( -90deg, rgb(82, 104, 130) 1%, rgb(136, 145, 155) 39%, rgb(217, 186, 160) 100%);
////
$color-qloc-text: $color-qloc-grey1;
////
$header-backgound: rgba(0, 0, 0, 0.25);
$header-height: 44px;
$menu-background: rgb(0, 0, 0, 0);
$menu-height: 80px;
$subheader-backgound:rgb(0, 0, 0, 0);
$subheader-height: 30rem;
$content-background: $color-qloc-grey7;
$testimonials-height: 300px;
$testimonials-bg-height: 600px;
$testimonials-bg: url('../assets/testimonials/testimonials_bg.webp');
$partners-height: 80px;
$partners-backgound: #FFFFFF;
$footer-height: 60px;
$footer-background: $color-qloc-primary1;